
  import { Action, Getter } from "vuex-class";
  import { Component, Vue } from "vue-property-decorator";
  import { IndexAppointmentPayload } from "@/store/modules/appointment.store";
  import { formatDate } from "@/utils/formatters";
  import { parse } from "fecha";
  import type { CalendarAction } from "@/store/modules/appointment.store";
  import { Seller } from "@/store/modules/seller.store";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      ActivityHeaderPart: () => import("@/views/seller/parts/activity/activity-header.vue"),
      ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
      PageTitle: () => import("./parts/general/page-title.vue"),
      PageToggle: () => import("./parts/general/page-toggle.vue"),
      PageSlider: () => import("./parts/general/page-slider.vue"),
      AppointmentCard: () => import("./parts/appointment/card.vue"),
    },
  })
  export default class PageSellerAppointments extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("appointment/all") appointments!: Appointment[];
    @Getter("activity/viewing") activity!: Activity;
    @Getter("appointment/actions") actions!: CalendarAction[];
    @Getter("seller/all") sellers!: Seller[];

    @Action("appointment/index") indexAppointments!: (payload: IndexAppointmentPayload) => Promise<Appointment[]>;
    @Action("appointment/actions") getActions!: () => Promise<CalendarAction[]>;

    formatDate = formatDate;

    tab = "future";

    get tabs() {
      return [
        { label: "Opkomend", value: "future", count: this.futureAppointments ? this.futureAppointments.length : undefined },
        { label: "Afgelopen", value: "past" },
      ];
    }

    loading = false;

    selected: Appointment | null = null;

    get futureAppointments() {
      return this.appointments
        ? this.appointments
            .filter((a: Appointment) => !this.isPast(a))
            .sort((a: Appointment, b: Appointment) => {
              if (new Date(a.planned_at).getTime() <= new Date(b.planned_at).getTime()) {
                return -1;
              } else {
                return 1;
              }
            })
        : undefined;
    }

    get pastAppointments() {
      return this.appointments
        ? this.appointments
            .filter((a: Appointment) => this.isPast(a))
            .sort((a: Appointment, b: Appointment) => {
              if (new Date(a.planned_at).getTime() >= new Date(b.planned_at).getTime()) {
                return -1;
              } else {
                return 1;
              }
            })
        : undefined;
    }

    get filteredAppointments() {
      if (this.tab === "future") {
        return this.futureAppointments;
      }
      if (this.tab === "past") {
        return this.pastAppointments;
      }
    }

    async mounted() {
      this.loading = true;

      let sellerId: boolean | number = false;
      if (!this.me.seller_id) {
        sellerId = this.sellers[0].id;
      }

      if (!this.actions.length) {
        await this.getActions();
      }

      await this.indexAppointments({ q: { and: { activity_id: Number(this.$route.params.id) } }, seller_id: sellerId });

      this.loading = false;
    }

    isPast(appointment: Appointment) {
      return parse(appointment.planned_at, "isoDateTime")!.getTime() + appointment.duration * 60 * 1000 < new Date().getTime();
    }
  }
